import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './Services/AuthGuard/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgxPrintModule} from 'ngx-print';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TokenInterceptorService } from './Services/tokenInterceptor/token-interceptor.service';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { LoginBranchSelectorComponent } from './starter/starter/login-branch-selector/login-branch-selector.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginBranchSelectorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxPrintModule,
    Ng2TelInputModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground : true,
    }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [AuthGuard, 
    {provide : HTTP_INTERCEPTORS, 
      useClass :TokenInterceptorService,
    multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
