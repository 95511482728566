import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest } from '@angular/common/http';
import { CRMLeadServiceService } from '../leadAPI/crmlead-service.service';
import { CalendarService } from '../calendarService/calendar.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private _injector : Injector) {
  }
  
  intercept(req, next){
     let CRMLeadService = this._injector.get(CRMLeadServiceService);
     //console.log(CRMLeadService.getTokenFromStorage())
     if(!this.isloginRequest(req)){

       let tokenizedReq = req.clone({
         setHeaders:{
           Authorization : `Bearer ${CRMLeadService.getTokenFromStorage()}`
          }
        })
        return next.handle(tokenizedReq);
      }
      return next.handle(req);
   }

   private isloginRequest(request: HttpRequest<any>): boolean {
    return request.url.includes('login');
   }
}
