import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-login-branch-selector',
  templateUrl: './login-branch-selector.component.html',
  styleUrls: ['./login-branch-selector.component.scss']
})
export class LoginBranchSelectorComponent implements OnInit {

  branches:any;
  loginData: { branches: any; preLoginToken: any; };
  preLoginToken: any;
  data: any;
  constructor(private leadApi: CRMLeadServiceService, private _router: Router) {

   }

  ngOnInit(): void {
    this.loginData = this.leadApi.getBranchesPreLogin();
    this.branches = this.loginData.branches.accountUserToBranch;
    this.preLoginToken = this.loginData.preLoginToken;
  }

  showBranchDetails(branch){
    this.leadApi.getTokenLogin({branchId: branch, preLoginToken: this.preLoginToken}).subscribe(
      data => {
        this.data = data;
      },
        responseError =>{
          alert("Please try with correct password or reach out to the support.")
        },
        ()=>{
        let token = this.data.access_token;

      localStorage.setItem('user', this.data.user.userEmail)
      localStorage.setItem('userId', this.data.user.id)
      localStorage.setItem('accountUserToBranchId', this.data.user.accountUserToBranchId)
      localStorage.setItem('branchId', this.data.user.branch.id)
      localStorage.setItem('accountId', this.data.user.branch.Account.id)
      localStorage.setItem('role', this.data.user.role)
      localStorage.setItem('canViewAllBranch', this.data.user?.permission?.canViewAllBranch)
      localStorage.setItem('canViewAdminPanel', this.data.user?.permission?.canViewAdminPanel)
      localStorage.setItem('canResetWhatsapp', this.data.user?.permission?.canResetWhatsapp)
      localStorage.setItem('canViewSuperAdmin', this.data.user?.permission?.canViewSuperAdmin)
      localStorage.setItem('canDeleteLead', this.data.user?.permission?.canDeleteLead)
      localStorage.setItem('canCreateBranch', this.data.user?.permission?.canCreateBranch)
      localStorage.setItem('canApproveCOE', this.data.user?.permission?.canApproveCOE)

      if(token){
        localStorage.setItem('token',token);
          this._router.navigate(['/user/dashboard']);
      }
      else{
        alert("Please enter the correct passowrd")
      }
      }
    )
  }
}
